import PropTypes from 'prop-types';
import React from 'react';

const IntroText = ({ title, subtitle, id, details, className, children }) => {
  return (
    <section
      id={id}
      className={
        'section-wrap-sm intro style-2' + (className ? ' ' + className : '')
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            {title && (
              <h1 className="bottom-line bellerose uppercase">{title}</h1>
            )}
            {subtitle && (
              <h2 className={title ? 'subheading' : 'heading'}>{subtitle}</h2>
            )}
            {details}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2 col-sm-12 text-center">
            <p className="intro-text intro-text-lead">{children}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

IntroText.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  id: PropTypes.string,
  details: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default IntroText;
