import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { trackGoal } from '../utilities/plausible';

const ContactForm = () => {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,

    formState: { isSubmitting, errors },
  } = useForm();

  const resetMessages = () => {
    if (showError) {
      setShowError(false);
    }

    if (showSuccess) {
      setShowSuccess(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      const result = await fetch('https://api.coiffeur-cocoon.ch/contact', {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });

      if (result.status === 200) {
        trackGoal('Contact Form: Submit', {
          props: { newsletter: data.newsletter },
        });
        setShowSuccess(true);
        reset();
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <section className="section-wrap-sm bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-40">
            <div className="contact-item mt-0">
              <div className="contact-icon">
                <i className="fa fa-map-marker"></i>
              </div>
              <p>
                <strong>Coiffeur Cocoon</strong>
                <br />
                Jasmin Sheila Brechbühl
                <br />
                Bernstrasse 15
                <br />
                3600 Thun
              </p>
              <p className="mt-10">
                <a
                  href="https://www.google.com/maps/place/Coiffeur+Cocoon/@46.7638012,7.6275315,17z/data=!3m1!4b1!4m6!3m5!1s0x478fb2dfdb632507:0x492db97b8434997a!8m2!3d46.7638012!4d7.6275315!16s%2Fg%2F11ckqr7fts?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Maps <i className="fa fa-external-link"></i>
                </a>
              </p>
            </div>

            <div className="contact-item">
              <div className="contact-icon">
                <i className="fa fa-phone"></i>
              </div>
              <span>
                <a href="tel:+41332211778">033 221 17 78</a>
              </span>
            </div>

            <div className="contact-item mt-10">
              <div className="contact-icon">
                <i className="fa fa-whatsapp"></i>
              </div>
              <span>
                <a
                  href="https://api.whatsapp.com/send?phone=41332211778"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  033 221 17 78
                </a>
              </span>
            </div>

            <div className="contact-item">
              <div className="contact-icon">
                <i className="fa fa-envelope"></i>
              </div>
              <a href="mailto:info@coiffeur-cocoon.ch">
                info@coiffeur-cocoon.ch
              </a>
            </div>

            <div className="contact-item">
              <div className="contact-icon">
                <i className="fa fa-calendar"></i>
              </div>
              <a
                href="/termin-buchen"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  trackGoal('Booking: Visit Form', {
                    props: { source: 'Contact Form' },
                  })
                }
              >
                Online Termin buchen
              </a>
            </div>
          </div>

          <div className="col-md-8">
            <form
              id="contact-form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="row">
                <div
                  className={'col-md-6' + (errors.firstname ? ' error' : '')}
                >
                  <input
                    {...register('firstname', {
                      required: 'Bitte gib deinen Vornamen ein.',
                    })}
                    id="firstname"
                    type="text"
                    placeholder="Vorname"
                  />
                  {errors.firstname && (
                    <div className="error-message">
                      <i className="fa fa-exclamation-circle"></i>
                      {errors.firstname.message}
                    </div>
                  )}
                </div>
                <div className={'col-md-6' + (errors.lastname ? ' error' : '')}>
                  <input
                    {...register('lastname', {
                      required: 'Bitte gib deinen Nachnamen ein.',
                    })}
                    id="lastname"
                    type="text"
                    placeholder="Nachname"
                  />
                  {errors.lastname && (
                    <div className="error-message">
                      <i className="fa fa-exclamation-circle"></i>
                      {errors.lastname.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className={'col-md-6' + (errors.email ? ' error' : '')}>
                  <input
                    {...register('email', {
                      required: 'Bitte gib deine E-Mail Adresse ein.',
                      pattern: {
                        value: /.+@.+\..+/,
                        message: 'Btte gib eine gültige E-Mail Adressse ein.',
                      },
                    })}
                    id="email"
                    type="email"
                    placeholder="E-Mail"
                  />
                  {errors.email && (
                    <div className="error-message">
                      <i className="fa fa-exclamation-circle"></i>
                      {errors.email.message}
                    </div>
                  )}
                </div>
                <div className={'col-md-6' + (errors.phone ? ' error' : '')}>
                  <input
                    {...register('phone', {
                      required: 'Bitte gib deine Telefonnummer ein.',
                    })}
                    id="phone"
                    type="text"
                    placeholder="Telefon"
                  />
                  {errors.phone && (
                    <div className="error-message">
                      <i className="fa fa-exclamation-circle"></i>
                      {errors.phone.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className={'col-md-12' + (errors.subject ? ' error' : '')}>
                  <input
                    {...register('subject', {
                      required: 'Bitte gib einen Betreff ein.',
                    })}
                    id="subject"
                    type="text"
                    placeholder="Betreff"
                  />
                  {errors.subject && (
                    <div className="error-message">
                      <i className="fa fa-exclamation-circle"></i>
                      {errors.subject.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className={'col-md-12' + (errors.message ? ' error' : '')}>
                  <textarea
                    {...register('message', {
                      required: 'Bitte gib eine Nachricht ein.',
                    })}
                    id="message"
                    rows="8"
                    placeholder="Nachricht"
                  ></textarea>
                  {errors.message && (
                    <div className="error-message">
                      <i className="fa fa-exclamation-circle"></i>
                      {errors.message.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div
                  className={
                    'col-md-12' + (errors.privacy ? ' error' : ' mb-30')
                  }
                >
                  <input
                    {...register('privacy', {
                      required: 'Bitte akzeptiere die Datenschutzerklärung.',
                    })}
                    id="privacy"
                    type="checkbox"
                    className="input-checkbox"
                  />
                  <label htmlFor="privacy">
                    Ich habe die{' '}
                    <a
                      href="/datenschutz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzerklärung
                    </a>{' '}
                    gelesen und akzeptiere diese.
                  </label>
                  {errors.privacy && (
                    <div className="error-message">
                      <i className="fa fa-exclamation-circle"></i>
                      {errors.privacy.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-30">
                  <input
                    {...register('newsletter')}
                    id="newsletter"
                    type="checkbox"
                    className="input-checkbox"
                  />
                  <label htmlFor="newsletter">
                    Ich möchte den kostenlosen Newsletter von Coiffeur Cocoon
                    abonnieren.
                  </label>
                </div>
              </div>

              <input
                type="submit"
                className="btn btn-lg btn-color btn-submit"
                value="Nachricht abschicken"
                disabled={isSubmitting}
                onClick={() => resetMessages()}
              />

              {isSubmitting && <i className="fa fa-spinner fa-spin ml-10"></i>}
            </form>

            {showError && (
              <div
                className="alert alert-danger alert-dismissible mt-30"
                role="alert"
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Schliessen"
                  onClick={() => setShowError(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <strong>Oh nein, es ist ein Fehler passiert... </strong>
                <br />
                Bitte versuche es später erneut oder kontaktiere uns über einen
                anderen Kanal.
              </div>
            )}

            {showSuccess && (
              <div
                className="alert alert-success fade in alert-dismissible mt-30"
                role="alert"
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Schliessen"
                  onClick={() => setShowSuccess(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <strong>Vielen Dank für deine Nachricht.</strong> <br /> Wir
                werden uns so schnell wie möglich bei dir melden.
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
