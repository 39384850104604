import React from 'react';

import ContactForm from '../components/contact-form';
import IntroText from '../components/intro-text';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';

const KontaktPage = (props) => {
  const title = 'Kontakt';

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Wenn du Fragen hast oder einen Termin vereinbaren möchtest, so kannst du dich jederzeit bei uns melden."
      />

      <IntroText title={title}>
        Wenn du Fragen hast oder einen Termin vereinbaren möchtest, so kannst du
        dich jederzeit bei uns melden. Verwende dazu einfach eine der
        untenstehenden Kontaktmöglichkeiten. Falls wir einmal nicht erreichbar
        sind, werden wir dich so schnell wie möglich kontaktieren.
      </IntroText>

      <ContactForm />

      <IntroText subtitle="Anreise">
        Du erreichst uns mit dem STI-Bus Nr. 1 ab Thun Bahnhof in Richtung
        Steffisburg Flühli (Haltestelle Berntor). Mit dem Auto kannst du
        kostenlos direkt vor dem Salon parkieren.
      </IntroText>
    </Layout>
  );
};

export default KontaktPage;
